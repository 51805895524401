import { Badge, CardTitle, UncontrolledTooltip } from "reactstrap";
import { useAppSelector } from "src/redux/hooks";

export interface PremiumLimitChipProps {
  premiumConnectionCount?: number;
}

export const PremiumLimitChip = (props: PremiumLimitChipProps) => {
  const { premiumConnectionCount: premiumDriverCount } = props;

  const subscription = useAppSelector((state) => state.subscription);
  const premiumDriverLimit = subscription.limits.premiumDriverLimit;

  if (premiumDriverLimit === undefined || premiumDriverCount === undefined) {
    return null;
  }

  // Set up premium sources badge
  let premiumBadgeText;
  let premiumBadgeColor = "secondary";
  let premiumBadgeTooltip = null;

  if (subscription.limits.premiumDriverLimit === -1) {
    premiumBadgeText = "Unlimited";
  } else if (premiumDriverCount > premiumDriverLimit) {
    premiumBadgeText = "Over Limit";
    premiumBadgeColor = "danger";
    const excessConnections = premiumDriverCount - premiumDriverLimit;
    const excessConnectionsMessage =
      excessConnections === 1
        ? `You are ${excessConnections} premium connection over your plan limit.`
        : `You are ${excessConnections} premium connections over your plan limit.`;
    premiumBadgeTooltip = (
      <UncontrolledTooltip placement="top" target="connectionsBadge">
        {excessConnectionsMessage}
      </UncontrolledTooltip>
    );
  } else {
    premiumBadgeText = `${premiumDriverCount}/${premiumDriverLimit}`;
  }

  return (
    <>
      <CardTitle className="mb-0">Premium Sources:</CardTitle>
      <span className="ms-2">
        <Badge id="connectionsBadge" color={premiumBadgeColor} size={16}>
          {premiumBadgeText}
        </Badge>
        {premiumBadgeTooltip}
      </span>
    </>
  );
};

import { NavigateFunction } from "react-router-dom";
import { getBetaDriverIconCard } from "../../../components/drivers/DriverIconFactory";
import { IPartialDriver } from "../../../models";
import { ApiConnectionPageType } from "../../apiConnector/ApiConnector";
import { compareStrings } from "../../../utility/CompareStrings";

export type DataSourceCard = {
  name: string;
  driverName: string;
  tile: JSX.Element;
  category: string;
  id: number;
  isPremium: boolean;
};

export const mapDataSourceCard = (
  driver: IPartialDriver,
  index: number,
  navigateFunction: NavigateFunction,
  connections: any[],
  isPremiumAndUserLacksFeature: boolean,
  isConnectionLimitReached?: boolean,
  isDataSourceLimitReached?: boolean,
  isPremiumDataSourceLimitReached?: boolean,
) => {
  return {
    name: driver.niceName,
    driverName: driver.driver,
    tile: getBetaDriverIconCard(
      driver,
      () =>
        navigateToConnections(
          navigateFunction,
          driver.driver!,
          driver.niceName!,
          connections,
        ),
      isPremiumAndUserLacksFeature,
      isConnectionLimitReached,
      isDataSourceLimitReached,
      isPremiumDataSourceLimitReached,
    ),
    category: driver.category,
    id: index,
    isPremium: driver.premium,
  } as DataSourceCard;
};

const navigateToConnections = (
  navigateFunction: NavigateFunction,
  driverType: string,
  driverNiceName: string,
  connections: any[],
) => {
  window.DD_RUM?.addAction("Navigating to Add Connection page", {
    driver: driverType,
  });

  const path = compareStrings(driverType, "REST")
    ? "/apiConnector"
    : "/connections/edit";
  const currentSearchParams = new URLSearchParams(window.location.search);
  const searchString = currentSearchParams.toString();
  const fullPath = `${path}?${searchString}`;

  navigateFunction(fullPath, {
    state: {
      driverType: driverType,
      driverNiceName: driverNiceName,
      connections: connections,
      apiConnectionPageType: ApiConnectionPageType.AddConnection,
    },
  });
};

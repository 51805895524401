import { Container } from "reactstrap";
import Loader from "../../components/Loader";
import { FeatureId, IConnection, UserRole } from "../../models";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import {
  compareDrivers,
  driverCategories,
} from "../../components/drivers/DriverUtilities";
import FilterGrid from "../../components/FilterGrid";
import { withRouter } from "../../components/withRouter";
import {
  DataSourceCard,
  mapDataSourceCard,
} from "./components/mapDataSourceCard";
import TitleWithBackButton from "../../components/TitleWithBackButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useAppSelector } from "../../redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { getConnections } from "./connectionList/getConnections";
import { compareStrings } from "src/utility/CompareStrings";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { useState } from "react";
import { CompareSourceTiersModal } from "./CompareSourceTiersModal";
import { BillingPlanIds } from "src/utility/enums/BillingPlanIds";

const SelectConnection = () => {
  const flags = useFeatureFlags().getFlags(["oracle_oci"]);
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.user);
  const subscription = useAppSelector((state) => state.subscription);

  const [showCompareTiersModal, setShowCompareTiersModal] = useState(false);

  const availableDriverCategories =
    subscription.limits?.availableDriverCategories ?? [];
  const isNewPlan = availableDriverCategories.length > 0;

  const premiumDriverLimit = subscription.limits.premiumDriverLimit;

  const connectionLimitPerSource =
    useAppSelector(
      (state) => state.subscription.limits?.connectionsPerSource,
    ) ?? -1;
  const driversList = useAppSelector((state) => state.driversList);
  const availableFeatureIds =
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [];
  const drivers = getDrivers();

  const isAdmin = currentUser.role === UserRole.Admin;

  // Query to load the connections
  const { data: connectionList, isLoading } = useQuery({
    queryKey: ["/account/connections"],
    queryFn: () =>
      getConnections({ IsAdmin: isAdmin, CurrentUserId: currentUser.id }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  function getDrivers() {
    let mappedDrivers = driversList.drivers!.map((driver) => driver);
    mappedDrivers = mappedDrivers.filter((driverItem) => {
      return driverItem.driver === "OracleOci"
        ? flags.oracle_oci.enabled
        : true;
    });
    const visibleDrivers = mappedDrivers.filter((driver) => !driver.hidden);
    return visibleDrivers.sort(compareDrivers);
  }

  function isDriverConnectionLimitReached(
    connections: IConnection[],
    driver: string,
  ) {
    if (connectionLimitPerSource === -1) {
      return false;
    }
    const driverConnections = connections.filter(
      (connection) => connection.driver === driver,
    );
    return driverConnections.length >= connectionLimitPerSource && !isNewPlan;
  }

  const isDataSourceLimitExceeded = (
    currentConnections: IConnection[],
    dataSourceLimit: number,
  ) => {
    const dataSourceCount = [
      ...new Set(currentConnections.map((connection) => connection.driver)),
    ].length;
    return dataSourceLimit > -1 && dataSourceCount >= dataSourceLimit;
  };

  const isPremiumLimitExceeded = (connections: IConnection[] | undefined) => {
    let premiumConnectionCount = 0;
    if (!isNewPlan || premiumDriverLimit === -1) {
      return false;
    }

    const premiumConnections = connections?.filter(
      (connection) =>
        drivers?.filter((driver) =>
          compareStrings(driver.driver, connection.driver),
        )[0]?.driverCategory === "C",
    );

    // Create set to remove duplicates from the list of premium connections
    premiumConnectionCount = new Set(
      premiumConnections?.map((connection) => connection.driver),
    ).size;

    if (
      premiumDriverLimit &&
      premiumConnectionCount &&
      premiumConnectionCount >= premiumDriverLimit
    ) {
      return true;
    } else {
      return false;
    }
  };

  function createDataSourceCards(connections: IConnection[]) {
    if (drivers.length > 0) {
      const newDataSourceCards: DataSourceCard[] = drivers.map(
        (driver, index) => {
          const featureIdToCheck =
            driver.driver === "REST"
              ? FeatureId.APIConnector
              : FeatureId.PremiumDataSources;

          const isPremiumAndUserLacksFeature =
            !isNewPlan &&
            driver.premium &&
            !isFeatureAllowed(availableFeatureIds, featureIdToCheck);

          return mapDataSourceCard(
            driver,
            index,
            navigate,
            connections,
            isPremiumAndUserLacksFeature,
            isDriverConnectionLimitReached(connections, driver.driver!),
            isDataSourceLimitReached,
            isPremiumLimitExceeded(connections),
          );
        },
      );

      return newDataSourceCards;
    }
  }

  let isDataSourceLimitReached = false;
  if (connectionList != null) {
    const connections = connectionList.connections?.map((connection) => ({
      ...connection,
      opsAllowed: 0,
      isCRAvailable: drivers.find(
        (driver) => driver.driver === connection.driver,
      )?.hasCustomReports,
    }));
    if (connections) {
      isDataSourceLimitReached = isDataSourceLimitExceeded(
        connections,
        connectionList.dataSourceLimit ?? 0,
      );
      createDataSourceCards(connections);
    }
  }

  const dataSourceCards =
    createDataSourceCards(connectionList?.connections ?? []) ?? [];

  if (isDataSourceLimitReached) {
    return <Navigate to="/connections" />;
  } else {
    if (isLoading || drivers.length === 0) {
      return (
        <Container fluid className="p-0">
          <Loader />
        </Container>
      );
    } else {
      return (
        <span className="select-connection-page">
          <div className="header-row">
            <TitleWithBackButton title={"Add Connection"} />
            {[
              BillingPlanIds.Standard2025,
              BillingPlanIds.Growth2025,
              BillingPlanIds.Business2025,
            ].includes(subscription.plan?.id ?? 0) && (
              <CDataButton
                buttonType={ButtonType.PrimaryOutline}
                className="compare-source-tiers"
                onClick={() => setShowCompareTiersModal(true)}
              >
                Compare Source Tiers
              </CDataButton>
            )}
          </div>
          <div className="list-connection-grid">
            <FilterGrid
              categories={driverCategories}
              tiles={dataSourceCards}
              tileTypes="Connections"
            />
          </div>
          <CompareSourceTiersModal
            showModal={showCompareTiersModal}
            setShowModal={setShowCompareTiersModal}
          />
        </span>
      );
    }
  }
};

export default withRouter(SelectConnection);

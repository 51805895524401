import { Badge, CardTitle, UncontrolledTooltip } from "reactstrap";
import { useIsConnectForSpreadsheets } from "src/hooks/useIsConnectForSpreadsheets";
import { useAppSelector } from "src/redux/hooks";

export interface ConnectionsChipProps {
  connectionLimit: number;
  connectionCount: number;
}

export const ConnectionsChip = (props: ConnectionsChipProps) => {
  const { connectionLimit, connectionCount } = props;

  const subscription = useAppSelector((state) => state.subscription);
  const isSpreadsheetUser = useIsConnectForSpreadsheets();

  const availableDriverCategories =
    subscription.limits?.availableDriverCategories;
  const isConnectionBadgeDisplayed =
    (!availableDriverCategories || availableDriverCategories.length === 0) &&
    !isSpreadsheetUser;

  if (!isConnectionBadgeDisplayed) {
    return null;
  }

  // Set up connection badge
  let connectionBadgeText;
  let connectionBadgeColor = "secondary";
  let connectionBadgeTooltip = null;

  if (connectionLimit === -1) {
    connectionBadgeText = "Unlimited";
  } else if (connectionCount > connectionLimit) {
    connectionBadgeText = "Over Limit";
    connectionBadgeColor = "danger";
    const excessConnections = connectionCount - connectionLimit;
    const excessConnectionsMessage =
      excessConnections === 1
        ? `You are ${excessConnections} connection over your plan limit.`
        : `You are ${excessConnections} connections over your plan limit.`;
    connectionBadgeTooltip = (
      <UncontrolledTooltip placement="top" target="connectionsBadge">
        {excessConnectionsMessage}
      </UncontrolledTooltip>
    );
  } else {
    connectionBadgeText = `${connectionCount}/${connectionLimit}`;
  }

  return (
    <>
      <CardTitle className="mb-0">Connections:</CardTitle>
      <span className="ms-2 me-3">
        <Badge id="connectionsBadge" color={connectionBadgeColor} size={16}>
          {connectionBadgeText}
        </Badge>
        {connectionBadgeTooltip}
      </span>
    </>
  );
};

import { Badge, CardTitle, UncontrolledTooltip } from "reactstrap";

export interface DataSourceChipProps {
  dataSourceLimit: number;
  dataSourceCount: number;
}

export const DataSourceChip = (props: DataSourceChipProps) => {
  const { dataSourceLimit, dataSourceCount } = props;

  // Set up data source badge
  let dataSourceBadgeText;
  let dataSourceBadgeColor = "secondary";
  let dataSourceBadgeTooltip = null;
  if (dataSourceLimit === -1) {
    dataSourceBadgeText = "Unlimited";
  } else if (dataSourceLimit !== -1 && dataSourceCount > dataSourceLimit) {
    dataSourceBadgeText = "Over Limit";
    dataSourceBadgeColor = "danger";
    const excessDataSources = dataSourceCount - dataSourceLimit;
    const excessDataSourcesMessage =
      excessDataSources === 1
        ? `You are ${excessDataSources} data source over your plan limit.`
        : `You are ${excessDataSources} data sources over your plan limit.`;
    dataSourceBadgeTooltip = (
      <UncontrolledTooltip placement="top" target="dataSourceBadge">
        {excessDataSourcesMessage}
      </UncontrolledTooltip>
    );
  } else {
    dataSourceBadgeText = `${dataSourceCount}/${dataSourceLimit}`;
  }

  return (
    <>
      <CardTitle className="ms-1 mb-0">Data Sources:</CardTitle>
      <span className="ms-2 me-3">
        <Badge id="dataSourceBadge" color={dataSourceBadgeColor} size={16}>
          {dataSourceBadgeText}
        </Badge>
        {dataSourceBadgeTooltip}
      </span>
    </>
  );
};

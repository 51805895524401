import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { CDataModalV2 } from "../../components/modal/CDataModalV2";
import CustomTextFilter from "../../components/CustomTextFilter";
import { Col, Row } from "reactstrap";
import { NotificationBar } from "../../components/notification/NotificationBar";
import { getSettingsPageSubscriptionTab } from "../../utility/SubscriptionProvider";
import { useNavigate } from "react-router-dom";
import { compareStrings } from "../../utility/CompareStrings";
import {
  getBetaIcon,
  getDriverIcon,
} from "../../components/drivers/DriverIconFactory";
import classNames from "classnames";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import { IConnectionItem } from "./Connections";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { compareDrivers } from "src/components/drivers/DriverUtilities";
import { IPartialDriver } from "src/models";
import { PremiumChipWithTitle } from "./PremiumChipWithTitle";
import { useAppSelector } from "src/redux/hooks";

interface IAddConnectionModal {
  connections: IConnectionItem[];
  connectionsPerSource: number;
  drivers: IPartialDriver[];
  isAtDataSourceLimit: boolean;
  showConnectionModal: boolean;
  setShowConnectionModal: (show: boolean) => void;
}

export const AddConnectionModal = (props: IAddConnectionModal) => {
  const {
    connections,
    connectionsPerSource,
    drivers,
    isAtDataSourceLimit,
    showConnectionModal,
    setShowConnectionModal,
  } = props;
  const navigate = useNavigate();
  const isSpreadsheetUser = useIsConnectForSpreadsheets();
  const subscription = useAppSelector((state) => state.subscription);
  const availableDriverCategories =
    subscription.limits?.availableDriverCategories ?? [];
  const isNewPlan = availableDriverCategories.length > 0;

  if (!showConnectionModal) return null;

  const driverList = [...drivers];

  const driverOptionColumns = [
    {
      dataField: "name",
      text: "",
      sort: false,
      hidden: true,
    },
    {
      dataField: "icon",
      text: "",
      sort: false,
    },
    {
      dataField: "niceName",
      text: "Driver Name",
      sort: false,
      filter: customFilter({}),
      filterRenderer: (onFilter: (value: string) => void, column: any) =>
        isAtDataSourceLimit ? (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            placeholder="Search by name..."
          />
        ) : (
          <></>
        ),
    },
    {
      dataField: "isBeta",
      text: "",
      sort: false,
      headerClasses: "is-beta-header",
    },
    {
      dataField: "isPremium",
      text: "",
      sort: false,
      headerClasses: "is-premium-header",
    },
  ];

  function isDriverConnectionLimitReached(driverName: string) {
    // This only applies to spreadsheet users with limited connections per source
    if (!isSpreadsheetUser || connectionsPerSource === -1) return false;

    const driverConnections = connections.filter(
      (connection) => connection.driver === driverName,
    );
    return driverConnections.length >= connectionsPerSource;
  }

  let driverOptions: any[] = [];
  const connectionDrivers = connections.map((connection) => connection.driver!);

  if (isAtDataSourceLimit) {
    driverList.sort(compareDrivers);

    driverOptions = driverList!
      .filter((driver) => connectionDrivers.includes(driver.driver!))
      .map((driver, index) => {
        const isPremiumAndOnNewPlan =
          isNewPlan && driver.driverCategory === "C";

        return {
          name: driver.driver,
          icon: getDriverIcon(driver.driver!, "driver-icon"),
          niceName: driver.niceName,
          id: index,
          isBeta: getBetaIcon(
            driver.beta || compareStrings(driver.driver, "APIConnector"),
            "badge-beta-add-connection",
          ),
          isPremium: isPremiumAndOnNewPlan ? (
            <PremiumChipWithTitle />
          ) : undefined,
          disabled: isDriverConnectionLimitReached(driver.driver!),
        };
      });
  }

  const rowClasses = (row: any) =>
    classNames("addConnectionRow-" + row.name, {
      "table-disabled": row.disabled,
    });

  const selectRow: SelectRowProps<any> = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#dee2e6",
    onSelect: (row) => {
      if (row.disabled) return false;
      navigate("/connections/edit", {
        state: {
          driverType: row.name,
          driverNiceName: row.niceName,
          connections: connections,
        },
      });
      setShowConnectionModal(false);
    },
  };

  const dataSourceLimitReachedBanner = (
    <Row className="mb-4" data-testid="data-source-limit-reached-banner">
      <Col>
        <NotificationBar
          message={`You have reached your data source limit. You can select new data
            sources by removing the connections attached to your current
            ones or by upgrading your subscription. You can still create new
            connections using one of your existing data sources below.`}
          barColor={"notification-bar-pale-orange"}
          linkText={"Upgrade"}
          linkClickOverride={() => {
            navigate(
              "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
            );
            setShowConnectionModal(false);
          }}
        />
      </Col>
    </Row>
  );

  return (
    <CDataModalV2
      title="Add Connection"
      close={() => setShowConnectionModal(false)}
      displayed={showConnectionModal}
      displayToggleCloseButton={true}
      className="add-connection-modal"
    >
      <div className="driver-options">
        <p>Select a data source from the list below to add a new connection.</p>
        {dataSourceLimitReachedBanner}
        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="id"
          data={driverOptions}
          columns={driverOptionColumns}
          selectRow={selectRow}
          filter={filterFactory()}
          rowClasses={rowClasses}
        />
      </div>
    </CDataModalV2>
  );
};

import { Badge } from "reactstrap";
import { CDataTypography } from "src/components/text/CDataTypography";

export const PremiumChipWithTitle = () => {
  return (
    <Badge color="primary" size={16} className="premium-chip-with-title">
      <i className="fa-solid fa-star" />
      <CDataTypography
        color="typography-color-white"
        variant="typography-variant-caption"
      >
        Premium
      </CDataTypography>
    </Badge>
  );
};

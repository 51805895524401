import "./CompareSourceTiersModal.scss";
import { useMemo } from "react";
import { DriverCategoryBadge } from "src/components/drivers/DriverCategoryBadge";
import { DriverIcon } from "src/components/drivers/DriverIcon";
import { compareDrivers } from "src/components/drivers/DriverUtilities";
import { CDataModalV2 } from "src/components/modal/CDataModalV2";
import { GridTable, GridTile } from "src/components/tables/GridTable/GridTable";
import { SimpleTile } from "src/components/tables/GridTable/SimpleTile";
import { useGridFilter } from "src/components/tables/GridTable/useGridFilter";
import { useAppSelector } from "src/redux/hooks";

interface ICompareSourceTiersModal {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

export const CompareSourceTiersModal = (props: ICompareSourceTiersModal) => {
  const { showModal, setShowModal } = props;

  const driversList = useAppSelector((state) => state.driversList);

  const drivers = useMemo(() => {
    const mappedDrivers = driversList.drivers!.map((driver) => driver);
    const visibleDrivers = mappedDrivers.filter((driver) => !driver.hidden);

    return visibleDrivers.sort(compareDrivers);
  }, [driversList.drivers]);

  const dataSourceCards = useMemo(() => {
    if (drivers.length > 0) {
      const newDataSourceCards: GridTile[] = drivers.map((driver) => {
        const categoryBadge = (
          <DriverCategoryBadge driverCategory={driver.driverCategory!} />
        );

        return {
          name: driver.niceName ?? "",
          category: driver.category ?? "",
          tile: (
            <SimpleTile
              name={driver.niceName ?? ""}
              onClick={() => null}
              isSelected={false}
              icon={<DriverIcon driver={driver.driver!} />}
              isReadOnly={true}
              endAdornment={categoryBadge}
            />
          ),
        };
      });

      return newDataSourceCards;
    }

    return [];
  }, [drivers]);

  const gridFilter = useGridFilter<GridTile>({
    tiles: dataSourceCards,
    categories: undefined,
  });

  return (
    <CDataModalV2
      close={() => setShowModal(false)}
      displayed={showModal}
      title={"Data Sources"}
      modalBodyProps={{ className: "compare-source-tiers-modal-body" }}
    >
      Explore the list below to compare data sources by tier.
      <GridTable
        tiles={dataSourceCards}
        emptyElement={<div className="text-center mt-4">No results found.</div>}
        scrollableContainerClass="mt-4"
        gridFilter={gridFilter}
      />
    </CDataModalV2>
  );
};
